import { FullPageLoading } from 'components/layout/full-page-loading';
import React from 'react';
import { useAuth } from 'context/auth-context';

const Authenticated = React.lazy(() => import('components/layout/authenticated'));
const Unauthenticated = React.lazy(() => import('components/layout/not-authenticated'));

const App: React.FC = () => {
  const authContext = useAuth();

  return (
    <React.Suspense fallback={<FullPageLoading />}>
      {authContext.isAuthenticated ? <Authenticated /> : <Unauthenticated />}
    </React.Suspense>
  );
};

export default App;
