import React from 'react';
import { Layout, Spin, Row, Col } from 'antd';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

const FullPageLoading = () => {
  return (
    <Layout>
      <Layout.Content>
        <StyledRow type="flex" justify="center" align="middle">
          <Col
            span={4}
            css={css`
              text-align: center;
            `}
          >
            <Spin size="large" />
          </Col>
        </StyledRow>
      </Layout.Content>
    </Layout>
  );
};

const StyledRow = styled(Row)`
  height: 100vh;
`;

export { FullPageLoading };
