class LocalStorage implements BrowserStorage {
  keys = {
    token: 'token',
    user: 'cl-wrh-user-key',
    refreshToken: 'tr',
    tokenExpirationDate: 'te'
  };

  constructor() {}

  // 'storage' events only fire on separate (active) windows
  // but we want to subscribe to localStorage changes in the current window as well
  // this will keep the app in sync with localStorage values (user / token data)
  dispatchStorageEvent(key: string): void {
    const event = new StorageEvent('storage', {
      key: key,
      newValue: window.localStorage.getItem(key)
    });

    window.dispatchEvent(event);
  }

  /**
   * Save value to the localStorage
   * @param key string Name of the localStorage key
   * @param value any
   */
  save(key: string, value: any): void {
    const serialized = JSON.stringify(value);
    window.localStorage.setItem(key, serialized);
    this.dispatchStorageEvent(key);
  }

  /**
   * Get value from localStorage by key
   * @param key string
   */
  get(key: string): string | null {
    const serialized = window.localStorage.getItem(key);
    if (serialized) {
      try {
        const value = JSON.parse(serialized);
        return value;
      } catch (err) {
        return null;
      }
    }

    return null;
  }

  /**
   * Remove value from localStorage by key
   * @param key string
   */
  remove(key: string): void {
    window.localStorage.removeItem(key);
    this.dispatchStorageEvent(key);
  }

  /**
   * Listen localStorage key update
   * @param key string
   * @param callback Callback function triggered once value got updated
   */
  listen(key: string, callback: (arg: string | null) => void): () => void {
    function listener(event: StorageEvent): void {
      if (event.key === key) {
        let value = null;
        if (event.newValue) {
          value = JSON.parse(event.newValue);
        }

        callback(value);
      }
    }

    window.addEventListener('storage', listener);

    // useEffect() needs this to properly unmount components
    return function(): void {
      window.removeEventListener('storage', listener);
    };
  }
}

// eslint-disable-next-line prefer-const
export let localStorage = new LocalStorage();
