import axios, { AxiosResponse, AxiosError, AxiosInstance } from 'axios';
import moment from 'moment';
import { localStorage } from 'services/storage';
import { parseFormErrors } from 'utils';

export const ApiEvents = {
  authFailed: 'api.authFailed'
};

/**
 * Handles all API requests + Auth
 */
class Api {
  readonly storage: BrowserStorage;
  readonly axios: AxiosInstance;
  readonly tokenRefreshThreshold: number;

  private publicRoutes = ['/login'];

  private isRefreshingToken = false;
  private authFailed = new Event(ApiEvents.authFailed);

  constructor(options: ApiOptions) {
    this.storage = options.storage;
    this.axios = options.axios;
    this.tokenRefreshThreshold = options.tokenRefreshThreshold;

    const token = this.storage.get(this.storage.keys.token);
    if (token) {
      this.setAuthHeader(token);
    }

    // Configure interceptors
    this.setupAxiosInterceptors();
  }

  /**
   * Helper to set Authentication header
   */
  private setAuthHeader(token: string): void {
    this.axios.defaults.headers.Authorization = `Bearer ${token}`;
  }

  /**
   * Setup axios interceptors
   */
  private setupAxiosInterceptors(): void {
    this.axios.interceptors.request.use(
      async config => {
        const token = await this.storage.get(this.storage.keys.token);
        // Check for token and cancel any request if we don't have token
        // Allow public routes requests without token
        if (!token && !(config.url && this.publicRoutes.indexOf(config.url) > -1)) {
          config = {
            ...config,
            cancelToken: new axios.CancelToken(cancel => cancel('No token provided'))
          };
        } else {
          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${token}`
          };
        }

        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );

    this.axios.interceptors.response.use(
      response => {
        // Maybe its time to revalidate the token
        const tokenExpire = this.storage.get(this.storage.keys.tokenExpirationDate);
        if (tokenExpire) {
          // Refresh token if we less than threshold milliseconds to expire date
          if (
            moment(tokenExpire).diff(moment(), 'milliseconds') <= this.tokenRefreshThreshold &&
            !this.isRefreshingToken
          ) {
            this.isRefreshingToken = true;
            this.maybeResignRequest().then(() => {
              this.isRefreshingToken = false;
            });
          }
        }

        return response;
      },
      (error: AxiosError) => {
        if (error.response && error.response.status === 401) {
          window.dispatchEvent(this.authFailed);
          //this.invalidate();
        }

        return Promise.reject(error);
      }
    );
  }

  /**
   * Invalidates current user by cleaning up localStorage
   */
  private invalidate(): void {
    this.isRefreshingToken = false;
    this.storage.remove(this.storage.keys.token);
    this.storage.remove(this.storage.keys.user);
    this.storage.remove(this.storage.keys.tokenExpirationDate);
    this.storage.remove(this.storage.keys.refreshToken);
  }

  /**
   * Refresh token
   */
  private refreshToken = (): Promise<Token> => {
    return new Promise((resolve, reject) => {
      return this.axios
        .post('/refresh-token', {
          refreshToken: this.storage.get(this.storage.keys.refreshToken)
        })
        .then((response: AxiosResponse<TokenResponse>) => {
          this.saveTokens(response.data.data);
          resolve(response.data.data);
        })
        .catch(error => {
          this.invalidate();
          reject(error);
        });
    });
  };

  /**
   * Resign request if token was expired invalid
   */
  private maybeResignRequest(): Promise<any> {
    return this.refreshToken().catch((error: any) => {
      // Token expired
      this.invalidate();
      return Promise.reject(error);
    });
  }

  /**
   * Saves all required tokens and sets auth header
   * @param authTokens token object
   */
  public saveTokens = (authTokens: Token): void => {
    this.setAuthHeader(authTokens.token);
    this.storage.save(this.storage.keys.token, authTokens.token);
    this.storage.save(this.storage.keys.tokenExpirationDate, authTokens.expiresAt);
    this.storage.save(this.storage.keys.refreshToken, authTokens.refreshToken);
  };

  // Public

  /**
   * Login method
   * @param email string
   * @param password string
   */
  login = async (args: any[], props: any, fetch: any): Promise<User> => {
    return new Promise((resolve, reject) => {
      this.axios
        .post('/login', { email: args[0], password: args[1] })
        .then((response: AxiosResponse<TokenResponse>) => {
          this.saveTokens(response.data.data);

          return this.axios.get('/me');
        })
        .then((response: AxiosResponse<UserResponse>) => {
          const { data: user } = response.data;

          resolve(user);
        })
        .catch((error: AxiosError) => {
          this.invalidate();

          if (error.response) {
            const response: ErrorResponse = error.response.data;

            // validation error case
            if (response.statusCode === 422) {
              const { message } = response as any;

              const errors = parseFormErrors<any>(message);
              reject(errors);
            }

            // user not found case
            if (response.statusCode === 404) {
              const {
                data: { message }
              } = response as NotFound;

              reject({
                password: 'The email/password combination used was not found on the system.'
              });
            }
          }

          // some kind of network error
          reject({ message: 'Network error!' });
        });
    });
  };

  /**
   * Fetch user profile
   */
  me = async (): Promise<User> => {
    return new Promise((resolve, reject) => {
      this.axios
        .get('/me')
        .then((response: AxiosResponse<UserResponse>) => {
          const { data: user } = response.data;

          resolve(user);
        })
        .catch((error: AxiosError) => {
          this.invalidate();

          // some kind of network error
          reject(error);
        });
    });
  };

  /**
   * Reset user password
   */
  resetPassword = async (args: string[], props: any, fetch: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      this.axios
        .post('/forgot-password', { email: args[0] })
        .then((response: AxiosResponse<Response<any>>) => {
          resolve(response.data);
        })
        .catch((error: AxiosError) => {
          if (error.response) {
            const response: ErrorResponse = error.response.data;

            // validation error case
            if (response.statusCode === 422) {
              const { message } = response as any;

              const errors = parseFormErrors<any>(message);
              reject(errors);
            }

            // user not found case
            if (response.statusCode === 404) {
              const { message } = response as any;

              reject({
                email: message
              });
            }
          }

          // some kind of network error
          reject({ message: 'Network error!' });
        });
    });
  };

  /**
   * Logs user out by removing key from local storage
   */
  logout = async (): Promise<any> => {
    // not sure what /logout does on BE, but we need the token to hit it
    // so we should wait for response before invalidating
    return this.axios
      .delete('/logout')
      .then(() => {
        this.invalidate();
      })
      .catch(err => {
        // still log them out
        this.invalidate();
      });
  };
}

const instance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 7000,
  headers: { 'Content-Type': 'application/json' }
});

export const wareHouseApi = new Api({
  storage: localStorage,
  axios: instance,
  tokenRefreshThreshold: parseInt(process.env.REACT_APP_TOKEN_REFRESH_THRESHOLD || '5000', 10)
});
