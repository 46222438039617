/**
 * Map form errors to their respective fields based on error response
 *
 * @param constraints
 */
export function parseFormErrors<T>(constraints: Constraints[]): any {
  return constraints.reduce((acc: any, constraint: Constraints) => {
    if (constraint.children.length > 0) {
      return {
        ...acc,
        [constraint.property]: {
          ...acc[constraint.property],
          ...parseFormErrors(constraint.children)
        }
      };
    }

    if (constraint.constraints) {
      const { property, constraints } = constraint;
      const [firstKey] = Object.keys(constraints);

      return {
        ...acc,
        [property]: constraints[firstKey]
      };
    }
  }, {});
}
