import React, { FC, useEffect, useMemo, useState } from 'react';

import { FullPageLoading } from 'components/layout/full-page-loading';
import { useAsync } from 'react-async';
import { useHistory } from 'react-router-dom';
import { wareHouseApi } from 'services/api';

interface AuthContextProps {
  user?: User;
  setUser: React.Dispatch<React.SetStateAction<User | undefined>>;
  isAuthenticated: boolean;
  logout: () => void;
}

const AuthContext = React.createContext<AuthContextProps>({
  user: undefined,
  isAuthenticated: false,
  setUser: () => {},
  logout: () => {}
});
AuthContext.displayName = 'AuthContext';

interface AuthProviderProps {}

const AuthProvider: FC = (props: AuthProviderProps) => {
  const [user, setUser] = useState<User | undefined>(undefined);
  const { data, isPending } = useAsync({ promiseFn: wareHouseApi.me });
  const { run: runLogout } = useAsync({ deferFn: wareHouseApi.logout });
  const history = useHistory();

  const isAuthenticated = useMemo(() => {
    return !!user;
  }, [user]);

  useEffect(() => {
    setUser(data);
  }, [data, isPending]);

  if (isPending) {
    return <FullPageLoading />;
  }

  /**
   * Handles logout
   */

  const logout = () => {
    runLogout();
    setUser(undefined);
    history.push('/');
  };

  return <AuthContext.Provider value={{ user, setUser, isAuthenticated, logout }} {...props} />;
};

/**
 * Helper hook to use AuthContext
 */
const useAuth = (): AuthContextProps => {
  const context = React.useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth can be used inside AuthProvider');
  }

  return context;
};

export { AuthProvider, useAuth };
