const colors = {
  primary: '#fc544c',
  secondary: '#152034',
  black: '#000',
  white: '#fff',
  brand: {
    green: 'rgb(165, 201, 61)',
    red: 'rgb(255, 0, 59)',
    blue: 'rgb(0, 155, 236)',
    yellow: '#deac54'
  }
};

const theme: Theme = {
  colors
};

export { theme };
