import * as serviceWorker from './serviceWorker';

import App from 'components/app';
import AppProviders from 'context';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'emotion-theming';
import { theme } from 'styles/theme';

ReactDOM.render(
  <Router>
    <ThemeProvider theme={theme}>
      <AppProviders>
        <App />
      </AppProviders>
    </ThemeProvider>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
